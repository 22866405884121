<template>
    <icon-base :width="size + 'px'" :fill="color">
        <g>
            <g>
                <g>
                    <g>
                        <g>
                            <g>
                                <path class="st0" d="M216.7,100.1c-2.9,3-5.8,5.9-8.7,8.9c-4.2,4.3-8.4,8.7-12.6,13c0.6,0.8,1.2,1.5,1.7,2.4        c4.8,7.7,5.2,17.2,0.9,24.9c-4.7,8.4-13.8,13.3-25.1,13.3h-43.5c-2.9,0-5.6-2.1-6-5c-0.5-3.6,2.3-6.7,5.8-6.7H173        c7,0,12.2-2.6,14.8-7.2c2.2-4,2-9-0.6-13c-1.2-1.9-2.8-3.5-4.7-4.7c-3.7-2.3-8.1-3-12.8-3c-16.6,0.1-33.2,0-49.7,0.1        c-1.4,0-2.7-0.2-4-0.7c-14.7-5.5-29.5-5.5-44.4-0.7c-14.1,4.6-24.9,13.3-32.4,26.1c-0.5,0.8-0.8,1.6-2.1,1.5        c-5.9-0.6-10.4,2.2-14.3,6.8c-5.1,6-10.5,11.7-16.5,16.8c-6.4,5.4-7.4,7.7-3,12.1c18.3,18.4,36.6,36.8,54.9,55.3        c1.3,0.1,2.2,0.1,3.6,0.2c0.9-0.7,1.9-1.3,2.7-2.1c7.5-7.4,15-14.8,22.6-22.2c4-4,5.4-8.7,4.3-14.2c-0.1-0.6-0.5-1.2,0.1-1.8        c4.5-4.5,8.9-8.7,16.1-8.6c20.1,0.3,40.3,0.1,60.4,0.1c2.7,0,5.4,0,8-0.3c10.9-1.3,20.3-5.7,27.4-14.1        c10.8-12.7,21.5-25.5,32-38.3c2-2.4,14.1-17.8,14.1-31.8C249.5,91.8,227.2,89.4,216.7,100.1z M80.2,208.9        c-6.2,6.2-12.5,12.3-18.7,18.5c-0.9,0.9-1.4,0.9-2.3,0c-15.3-15.4-30.5-30.8-45.8-46.2c-0.8-0.8-0.9-1.2,0-2.1        c6.2-6.2,12.4-12.4,18.6-18.6c2.4-2.3,4.9-2.3,7.3,0.1c13.7,13.6,27.4,27.3,41.1,41c1,1,1.6,2.2,1.6,3.7        C81.9,206.7,81.3,207.8,80.2,208.9z M111.1,161.1c-1.9-0.4-3.4-1.9-3.8-3.8c-0.7-3.2,1.9-6,5-5.9c2.7,0.1,4.7,2.2,4.6,4.9        C116.8,159.4,114.2,161.7,111.1,161.1z"/>
                            </g>
                        </g>
                        <g>
                            <path class="st1" d="M130.6,15.2v3.6c-4.6,0.9-8.4,2.8-11.3,5.7c-3.3,3.2-4.9,7.2-4.9,11.8c0,3.8,1,7,3,9.3       c1.9,2.2,4.2,3.9,6.8,5c2.5,1,5.7,2.1,9.6,3c2.7,0.7,4.9,1.3,6.4,1.9c1.3,0.5,2.5,1.1,3.3,1.9c0.7,0.6,1,1.4,1,2.5       c0,2-0.7,3.5-2.2,4.6c-1.6,1.2-4,1.7-7.1,1.7c-2.3,0-4.6-0.3-6.8-1s-4.4-1.8-6.7-3.3c-0.8-0.4-1.4-0.8-1.9-1       c-0.6-0.2-1.3-0.4-2-0.4c-0.9,0-2.2,0.3-3.4,1.8c-0.8,1.1-1.3,2.4-1.3,3.8c0,1,0.2,1.9,0.5,2.7c0.4,0.8,1,1.5,1.8,2.1       c2.1,1.7,4.7,3.1,7.6,4.1c2.4,0.9,5,1.5,7.6,1.8v3.4c0,1.6,0.5,2.9,1.5,4c1,1,2.4,1.6,4,1.6s2.9-0.5,3.9-1.6c1-1,1.5-2.4,1.5-4       v-3.6c4.6-0.9,8.3-2.8,11.1-5.5c3.1-3.1,4.7-7,4.7-11.5c0-3.8-1-6.8-2.9-9.1c-1.8-2.2-4.1-3.8-6.7-4.9c-2.5-1-5.6-2-9.4-2.9       c-2.8-0.7-5-1.3-6.6-1.9c-1.4-0.5-2.6-1.2-3.5-2.1c-0.8-0.8-1.2-1.8-1.2-3c0-2,0.7-3.5,2.2-4.6c1.6-1.2,3.9-1.8,6.8-1.8       c2.4,0,4.4,0.3,6.1,1c1.8,0.7,3.7,1.8,5.7,3.2c1,0.6,1.6,0.9,2,1c0.6,0.2,1.2,0.3,1.9,0.3c0.9,0,2.3-0.3,3.5-1.8       c0.9-1.1,1.3-2.4,1.3-3.8c0-2-0.9-3.6-2.4-4.8c-3.6-3-8-4.9-12.9-5.8v-3.5c0-1.6-0.5-2.9-1.5-3.9c-2-2.1-6-2.1-7.9,0       C131.1,12.3,130.6,13.6,130.6,15.2z"/>
                        </g>
                    </g>
                </g>
            </g>
        </g>
    </icon-base>
</template>
<script>

export default {
name: 'iconCash',
components: {
    iconBase: () => import('@/common/components/iconBase.vue')
},
props: {
    size: {
        type: Number,
        default: 20
    },
    color: {
        type: String,
        default: 'var(--main-navy)'
    },
    
}
}
</script>

<style scoped>
    .st0{fill:#234F6D;}
    .st1{fill:#8ECC6E;}
</style>
