<template>
<div>
    <main-modal  @closeModal="$emit('closeModal')" :title="'account.write_review'">
        <div class="row overflow-auto" style="max-height: 650px;">
            <create-review inOrderDetails class="form-container" @formReview="$emit('closeWriteReview')" :editData="null" :code="product.codarticulo" @errorReview="exception = { show: true, type: 'review'}" />
        </div>
    </main-modal>
    <error-review-question v-if="exception.show" @closeModal="reloadPage" title="product.error_title" :description="`product.error_${exception.type}`"/>
</div>
</template>

<script>
import MainModal from '../../../../common/components/modals/MainModal.vue'
import 'vue2-dropzone/dist/vue2Dropzone.min.css'
import { http } from "@/common/services/node-service";
import { mapGetters } from "vuex"
import CreateReview from "@/modules/products/components/CreateReview.vue"
import ErrorReviewQuestion from '../../../products/components/modals/ErrorReviewQuestion.vue';

export default {
    components: { MainModal, CreateReview, ErrorReviewQuestion },
    name: "WriteReview",
    props:{
        product:{
            type: Object
        }
    },
    data() {
        return {
            dropzoneOptions: {
                    url: '/',
                    maxFiles: 2,
                    thumbnailWidth: 200,
                    resizeQuality: .6,
                    addRemoveLinks: true,
                    autoProcessQueue: false,
                    addedfiles: (file) => { this.afterComplete(file)},
                    // headers: { "Authorization": "Bearer "+localStorage.getItem('token') },
                    resizeWidth: 1000,
                    resizeHeight: 1000,
                    acceptedFiles: ".jpeg,.jpg,.png",
                    dictDefaultMessage: `
                    <div>
                    <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" id="Checkout" x="0px" y="0px" viewBox="0 0 250 250" style="width:50px; fill:#a5b2b7" xml:space="preserve">
                    <g>
                        <path class="st3" d="M160.1,145.8c-0.4-0.6-0.8-1.1-1.3-1.6c-2.2-2.1-5.6-2.3-7.9-0.1c-2.3,2.1-4.4,4.4-6.6,6.5   c-2.4,2.3-5.2,3-7.7,1.5c-1.1-0.6-2-1.6-2.8-2.6c-3.3-4.2-6.5-8.5-9.8-12.7c-5-6.5-10-13.1-15-19.6c-3-3.8-6.2-3.5-8.4,0.8   c0,0,0,0.1-0.1,0.1c-8.3,16.4-16.6,32.9-24.9,49.3c-0.4,0.8-0.7,1.6-0.9,2.5c-0.4,2,0.6,3.8,2.6,4.3c1,0.3,2.2,0.4,3.3,0.4   c15.2,0,30.3,0,45.5,0c15.4,0,30.8,0,46.2,0c1.7,0,3.6,0,4.5-1.8c0.9-1.8-0.1-3.3-1.1-4.8C170.4,160.5,165.3,153.1,160.1,145.8z"/>
                        <path class="st3" d="M144.9,113.8c8.2,0,14.9-6.6,14.9-14.8c0-8.2-6.6-14.9-14.8-14.9c-8.1,0-14.9,6.6-14.9,14.8   C130,107.1,136.7,113.8,144.9,113.8z"/>
                        <path class="st3" d="M125,0.5C56.2,0.5,0.5,56.2,0.5,125S56.2,249.5,125,249.5c68.8,0,124.5-55.7,124.5-124.5S193.8,0.5,125,0.5z    M192.9,173c0,1.8-0.3,3.6-0.9,5.3c-2.2,6.4-7.9,10.3-15,10.3c-26.7,0-53.4,0-80,0c-8.1,0-16.3,0-24.4,0c-6,0-10.6-2.7-13.5-7.8   c-1-1.7-1.4-3.8-2-5.7c0-33.4,0-66.9,0-100.3c0.1-0.2,0.2-0.3,0.2-0.5c1.5-8,7.5-13,15.6-13c34.8,0,69.5,0,104.3,0   c9,0,15.7,6.7,15.7,15.7C193,109,193,141,192.9,173z"/>
                    </g>
                    </svg>
                    <h6 class="m-0 my-1" style="font-family: 'Rubik'; font-weight: 500;">Subir Imagen</h6>
                    <span style="color: #a5b2b7; font-family: 'Rubik'; font-weight: 300;">Arrastre o cargue la imagen deseada</span>
                    </div>`
            },
            review: {
                rated: 0,
                title: "",
                full_name: "",
                images: [],
                comment: ''
            },
            exception: {
                show: false,
                type: null,
            },
            labelButton: 'account.send'
        }
    },
    computed:{
        ...mapGetters({
                user:"auth/userData",
        })
    },
    methods:{
        reloadPage(){
            this.$router.go();
        },
        async afterComplete(upload) {
            try {
                let file = upload[0];
                this.review.images.push({  name: file.name, file });
                if (this.review.images === 5){
                    this.$refs.myVueDropzone2.disable();
                }
            } catch (error) {
                console.log(error)
                // await this.$store.dispatch('alert/set_alert', { type: 'Danger', msg: error })
            }
        }, 
        async sendReview() {
            try {
                this.labelButton = "account.sending";
                this.$refs.name.$v.$touch();
                this.$refs.title.$v.$touch();
                if (this.$refs.name.$v.$invalid || this.$refs.title.$v.$invalid) {
                    return;
                }
                this.sending = true;
                let fd = new FormData();
                let newImages = this.review.images.filter((image) => {
                    // eslint-disable-next-line no-prototype-builtins
                    return image.hasOwnProperty("blob");
                });

                for (let key in newImages) {
                fd.append(
                    "files[" + key + "]",
                    newImages[key].file,
                    newImages[key].name
                );
                }

                fd.append("rated", this.review.rated);
                fd.append("title", this.review.title);
                fd.append("comment", this.review.comment);
                fd.append("full_name", this.review.full_name);
                await http.post(`products/${this.product._id}/reviews`, fd, {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
                });

                await this.$store.dispatch("alert/set_alert", {
                type: "success",
                msg: this.$t("review.thanks_msg"),
                });
                this.$emit("closeModal")
            } catch (e) {
                // console.log(e.message)
                console.log(e);
            } finally {
                this.sending = false;
                this.labelButton = "account.send";
            }
        },    
    },
    created(){
        this.review.full_name = this.user.entity.first_name + ' ' + this.user.entity.last_name
    }
}
</script>

<style scoped>
    textarea{
        border-color: #cedde9 !important;
        border-radius: 7px !important;
    }
    .vue-dropzone {
        border-style: dashed;
    }
    .form-container {
        width: 650px;
        padding-bottom: 0px !important;
    }
</style>