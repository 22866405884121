<template>

    <transition name="blink">
        <div v-show="enableBtn" class="position-fixed" style="z-index: 12;" @click="scroll">
            <div class="scroll-btn d-flex align-content-center justify-content-center position-fixed bg-dark-main-red cursor-pointer">
                <icon-arrow style="transform: rotate(-90deg); margin-bottom: 3px;" :size="23" color="white" />
            </div>
        </div>
    </transition>
</template>

<script>
import IconArrow from '../../svg/iconArrow.vue'
export default {
    name: 'ScrollUpButton',
    components: { IconArrow },
    data() {
        return {
            enableBtn: false,
        }
    },
    methods:{
        scroll(){
            window.scroll(0,0)
        },
        handleScroll(){
            if (window.scrollY > 40) {
                this.enableBtn = true
            } else {
                this.enableBtn = false
            }
        }
    },
    created () {
        window.addEventListener('scroll', this.handleScroll);
    },
    destroyed () {
        window.removeEventListener('scroll', this.handleScroll);
    },
}
</script>

<style scoped>    
    .scroll-btn {
        right: 20px;
        bottom: 90px;
        
        border-radius: 3.123rem;
        width: 40px;
        height: 40px;
        box-shadow: 0px 0px 10px 0.5px #909da466;
        transition: var(--transition-1);
    }
    .scroll-btn:active {
        transform: scale(0.75);
    }

    .blink-enter-active, .blink-leave-active {
        transition: opacity .15s ease-in-out;
    }
    .blink-enter, .blink-leave-to {
        opacity: 0;
    }
</style>