<template>
    <div>
        <h3 class="title-18 mb-4">{{$t("checkout.payment_methods")}}</h3>
        <div>
            <ul class="py-0 mb-0 list-unstyled alignment-padding">
                <div v-for="(payment, index) in checkPayment" :key="index" :class="[checkPayment.length > 1 ? 'payments' :'']">
                    <li v-if="payment.type === 'Pago en efectivo'">
                        <div class="d-flex align-items-center justify-content-between">
                            <span class="subtitle-14 color-dark-gray-300">
                                <icon-confirmation-cash :size="35" :color="'var(--green-600)'" class="me-2"/>
                                <b class="weight-400 d-inline d-md-block d-xl-inline">{{$t("checkout.cash")}}</b>
                            </span>
                            <div>
                                <span class="color-dark-main-navy subtitle-14"> 
                                    {{payment.total_amount | moneyFormat}}
                                </span>
                            </div>
                        </div>
                    </li>
                    <li v-if="payment.type === 'cash'">
                        <div class="d-flex align-items-center justify-content-between">
                            <span class="subtitle-14 color-dark-gray-300">
                                <icon-confirmation-cash :size="35" :color="'var(--green-600)'" class="me-2"/>
                                <b class="weight-400 d-inline d-md-block d-xl-inline">{{$t("checkout.cash")}}</b>
                            </span>
                            <div>
                                <span class="color-dark-main-navy subtitle-14"> 
                                    {{payment.amount | moneyFormat}}
                                </span>
                            </div>
                        </div>
                    </li>
                    <!-- <li v-if="payment.type === 'Stripe'">
                        <div class="d-flex align-items-center justify-content-between">
                            <span class="subtitle-14 color-dark-gray-300">
                                <icon-card  :size="35" :color="'var(--green-600)'" class="me-2"/>
                                {{$t("Credit or debit card")}}
                            </span>
                            <div>
                                <span class="color-dark-main-navy subtitle-14"> 
                                    {{payment.total_amount | moneyFormat}}
                                </span>
                            </div>
                        </div>
                    </li> -->
                    <li v-if="payment.type === 'Depósito Express'">
                        <div class="d-flex align-items-center justify-content-between">
                            <span class="subtitle-14 color-dark-gray-300">
                                <icon-deposit-express :size="30" :color="'var(--green-600)'" class="me-2"/>
                                {{$t("checkout.bank_deposit")}}
                            </span>
                            <div>
                                <span class="color-dark-main-navy subtitle-14"> 
                                    {{payment.total_amount | moneyFormat}}
                                </span>
                            </div>
                        </div>
                    </li>
                    <li v-if="payment.type === 'Paypal'">
                        <div class="d-flex align-items-center justify-content-between">
                            <span class="subtitle-14 color-dark-gray-300">
                                <icon-paypal :size="80" :color="'var(--green-600)'" class="me-2"/>
                                {{$t("checkout.paypal")}}
                            </span>
                            <div>
                                <span class="color-dark-main-navy subtitle-14"> 
                                    {{payment.total_amount | moneyFormat}}
                                </span>
                            </div>
                        </div>
                    </li>
                    <li v-if="payment.type === 'Azul' || payment.type === 'Stripe'">
                        <div class="d-flex align-items-center mb-3">
                                <icon-visa v-if="payment.card_token.card_brand.toLowerCase() == 'visa'" viewBox="0 80 250 100" :size="50" />
                                <icon-mastercard v-if="payment.card_token.card_brand.toLowerCase() == 'mastercard'" :size="50" />
                                <icon-discover v-else-if="payment.card_token.card_brand.toLowerCase() == 'discover'" :size="55" />
                                <icon-troy v-else-if="payment.card_token.card_brand.toLowerCase() == 'troy'" :size="50" />
                                <icon-union-pay v-else-if="payment.card_token.card_brand.toLowerCase() == 'unionpay'" :size="20" />
                                <icon-diners-club v-else-if="payment.card_token.card_brand.toLowerCase() == 'dinersclub'" :size="50" />
                                <icon-j-c-b v-else-if="payment.card_token.card_brand.toLowerCase() == 'jcb'" :size="55" />
                                <icon-american-express v-else-if="payment.card_token.card_brand.toLowerCase() == 'amex'" :size="55" />
                                <div class="d-flex flex-column ms-3">
                                    <span class="d-flex flex-lg-column flex-xl-row size-13">
                                        <span class="color-dark-gray-300">{{payment.card_token.card_brand | textFormat}}</span> 
                                        <span class="ms-2 ms-lg-0 ms-xl-2">**** {{payment.card_token.card_last_four_digits}}</span>
                                    </span>
                                    <span class="size-13">{{$t('account.expiration')}} {{payment.card_token.card_expiry_month}}/{{payment.card_token.card_expiry_year}}</span>
                                </div>
                                <span class="color-dark-main-navy subtitle-14 ms-auto"> 
                                    {{payment.total_amount | moneyFormat}}
                                </span>
                        </div>

                    </li>
                    <li v-if="payment.type === 'Pago a credito'">
                        <div class="d-flex align-items-center justify-content-between">
                            <span class="subtitle-14 color-dark-gray-300">
                                <icon-only-credit :size="60" class="me-2"/>
                                {{$t("checkout.credit")}}
                            </span>
                            <div>
                                <span class="color-dark-main-navy subtitle-14"> 
                                    {{payment.total_amount | moneyFormat}}
                                </span>
                            </div>
                        </div>
                    </li>
                    <li v-if="payment.type == 'credit'">
                        <div class="d-flex align-items-center justify-content-between">
                            <span class="subtitle-14 color-dark-gray-300">
                                <icon-only-credit :size="60" class="me-2"/>
                                {{$t("checkout.credit")}}
                            </span>
                            <div>
                                <span class="color-dark-main-navy subtitle-14"> 
                                    {{payment.amount | moneyFormat}}
                                </span>
                            </div>
                        </div>
                    </li>
                    <li v-if="payment.type === 'Nota de credito'">
                        <div class="d-flex align-items-center justify-content-between">
                            <span class="subtitle-14 color-dark-gray-300">
                                <icon-note-credit :size="40" class="me-2"/>
                                {{$t("checkout.credit_note")}}
                            </span>
                            <div>
                                <span class="color-dark-main-navy subtitle-14"> 
                                    {{payment.total_amount | moneyFormat}}
                                </span>
                            </div>
                        </div>
                    </li>
                    <li v-if="payment.type === 'Deposito'">
                        <div class="d-flex align-items-center justify-content-between">
                            <span class="subtitle-14 color-dark-gray-300">
                                <icon-deposit :size="40" class="me-2"/>
                                {{$t("checkout.deposit")}}
                            </span>
                            <div>
                                <span class="color-dark-main-navy subtitle-14"> 
                                    {{payment.total_amount | moneyFormat}}
                                </span>
                            </div>
                        </div>
                    </li>
                </div>
            </ul>
        </div>
        <hr class="mt-0">
        <ul class="py-0 list-unstyled alignment-padding">
            <li class="d-flex justify-content-between subtitle-14 mt-2 color-dark-gray-300">{{$t("account.subtotal")}} <span>{{order.amount_sub | moneyFormat}}</span> </li>
            <li class="d-flex justify-content-between subtitle-14 mt-2 color-dark-gray-300">{{$t("account.tax")}} <span>{{order.amount_tax | moneyFormat}}</span></li>
            <!-- <li class="d-flex justify-content-between subtitle-14 mt-2 color-dark-gray-300">Descuentos<span>{{order.amount | moneyFormat}}</span></li> -->
        </ul>
        <hr class="dashed">
        <div class="d-flex justify-content-between title-14 mb-0 alignment-padding">
            <span>{{$t("account.order_total")}}</span>
            <span>{{order.amount | moneyFormat}}</span>
        </div>
    </div>
</template>

<script>
import IconAmericanExpress from '../../../checkout/assets/svg/iconAmericanExpress.vue'
import IconDinersClub from '../../../checkout/assets/svg/iconDinersClub.vue'
import IconDiscover from '../../../checkout/assets/svg/iconDiscover.vue'
import IconJCB from '../../../checkout/assets/svg/iconJCB.vue'
import IconMastercard from '../../../checkout/assets/svg/iconMastercard.vue'
import IconTroy from '../../../checkout/assets/svg/iconTroy.vue'
import IconUnionPay from '../../../checkout/assets/svg/iconUnionPay.vue'
import IconVisa from '../../../checkout/assets/svg/iconVisa.vue'
import iconConfirmationCash from '../../../checkout/assets/svg/iconConfirmationCash.vue'
import IconDeposit from '../../../checkout/assets/svg/iconDeposit.vue'
import IconDepositExpress from '../../../checkout/assets/svg/iconDepositExpress.vue'
import IconNoteCredit from '../../../checkout/assets/svg/iconNoteCredit.vue'
import IconOnlyCredit from '../../../checkout/assets/svg/iconOnlyCredit.vue'
import IconPaypal from '../../../checkout/assets/svg/iconPaypal.vue'
// import IconCard from '../../../../common/svg/iconCard.vue'
export default {
    name: "PaymentsDetails",
    components: { 
        iconConfirmationCash, 
        IconDepositExpress, 
        IconPaypal, 
        // IconCard,
        IconVisa,
        IconMastercard,
        IconDiscover,
        IconTroy,
        IconUnionPay,
        IconDinersClub,
        IconJCB,
        IconAmericanExpress, 
        IconOnlyCredit, 
        IconNoteCredit, 
        IconDeposit 
    },
    computed: {
        checkPayment(){
            if(this.order?.order_primary){
                return this.order.order_primary.payments
            } else {
                return [this.order.payment]
            }
        }
    },
    props: {
        order: {
            type: Object
        }
    },
    filters: {
        yearFormat(year){
            return (year).toString().slice(-2);
        }
    }
}
</script>

<style scoped>
    .dashed {
        background-color: #fff;
        border-top: 2px dashed #607D8B;
        opacity: 0.2;
    }
    .payments{
        padding-bottom: 20px;
    }
    .alignment-padding{
    padding-left: 1.3rem;
    padding-right: 1.7rem;
}
@media (min-width : 768px) {
    .alignment-padding{
        padding-left: 1rem;
        padding-right: 2px;
    }
}
@media (min-width : 992px) {
    .card-order{
        padding: 35px 45px;
    }
    .alignment-padding{
        padding: 0 0;
    }
}

</style>