<template>
   <div v-if="order">
      <!-- nav & scroll-marker -->
      <scroll-view> 
         <template slot-scope="orderDetail">
            <scroll-marker :debug="showScrollTrackers" class="mt-0" name="general" key="general" :visible="orderDetail.general" />
         </template>
      </scroll-view>
      <!-- testing purposes -->
      <div class="d-none section-percentages position-fixed p-3 bg-white d-block" style="min-width: 16-0px;">
         <span v-for="(value, name, index) of sectionVisible" :key="index" class="d-block">
            <b class="weight-600">{{name}}:</b> <b class="weight-400" :style="{color: value === 100 ? 'var(--main-red)':'var(--dark-gray-200)'}" >{{value}}</b>
         </span>
      </div>
      <!-- testing purposes -->
      <router-link to="/account/orders" class="orders-link weight-400 px-1 size-14">
            <icon-arrow :size="14" class="me-1" style="transform: rotate(180deg);"/>
            <span class="color-blue-600 ps-1">{{$t("account.back_to_my_orders")}}</span>
      </router-link>
      <div>
         <h4 class="mt-5 title-24 px-2">
            <icon-clipboard :size="28" style="transform: translateY(-1px)" />
            {{$t("account.order_details")}}
         </h4>
      </div>
      <div class="row justify-content-between flex-wrap">
         <div class="col-lg-8 col-xl-8 ps-lg-4 ps-xl-5 pe-md-0" style="max-width: 840px;">
            <div class="options-tab">
               <main-nav-tab ref="mainNav" :options="optionsTab" :selectedOption="activeScroll" @select="(data) => scrollTo(data)" />
            </div>
            <card-order-intranet v-if="typeInvoices == 'invoices'" ref="cardOrder" id="general" :order="order" @getOrders="getOrder" isOrderDetails class="order-details p-0 mt-2" />
            <card-order v-if="typeInvoices != 'invoices'" ref="cardOrder" id="general" :order="order" @getOrders="getOrder" isOrderDetails class="order-details p-0 mt-2" />
            <hr class="">
            <scroll-view>  <!-- nav-marker -->
               <template slot-scope="orderDetail">
                  <scroll-marker :debug="showNavTrackers" class="mt-0" name="track" key="track" :visible="orderDetail.track"/>
               </template>
            </scroll-view>
            <track-order ref="trackOrder" id="track" class="px-lg-2" :order="order" :filterStateOrderById="order.order_status" style="margin-top: 36px;" />
            <scroll-view> <!-- nav-marker -->
               <template slot-scope="orderDetail">
                  <scroll-marker :debug="showNavTrackers" class="mt-0" name="information" key="information" :visible="orderDetail.information" />
               </template>
            </scroll-view>
            <div class="position-relative">
               <product-information ref="productInformation" id="information" :order="order" class="px-lg-2 mt-4" />
            </div>
         </div>
         <div class="right-panel col-12 col-lg-4">
            <card>
               <payments-details :order="order"/>
            </card>
            <card v-if="typeInvoices != 'invoices'" style="margin-top: 2rem;">
               <pick-up-details v-if="order.order_primary.option_name == 'pick_up'" :partner="order.order_primary.partner == null ? user : order.order_primary.partner " :branch="this.branches | filterBranch(order.order_primary.optionaddress.branch_code)" />
               <address-details v-else :address="order.order_primary.optionaddress.address" :delivery="order.amount_delivery" :order="order"/>
            </card>
         </div>
      </div>      
      <div class="mt-5">
         <router-link to="/account/orders" class="orders-link size-14 weight-400 px-1">
            <icon-arrow class="me-1" :size="14" style="transform: rotate(180deg);"/>
            {{$t("account.back_to_my_orders")}}
         </router-link>
      </div>
   </div>
</template>

<script>
import MainNavTab from '../../../../common/components/tabs/MainNavTab.vue';
import { $scrollview } from 'vue-scrollview'
import { http } from "../../../../common/services/web-backend";
import CardOrder from './CardOrder.vue';
import TrackOrder from './TrackOrder.vue';
import { mapGetters } from "vuex"
import Card from '../../../../common/components/cards/Card.vue';
import CardOrderIntranet from './CardOrderIntranet.vue';
import AddressDetails from './AddressDetails.vue';
import PaymentsDetails from './PaymentsDetails.vue';
import ProductInformation from './ProductInformation.vue';
import IconArrow from '../../../../common/svg/iconArrow.vue';
import IconClipboard from "../../assets/svg/iconClipboard.vue";
import PickUpDetails from './PickUpDetails.vue';

export default {
   components: { MainNavTab, CardOrder, TrackOrder, ProductInformation, IconArrow, IconClipboard, CardOrderIntranet, PickUpDetails, AddressDetails, Card, PaymentsDetails},
   name: "OrderDetails",
   data() {
      return {
         optionsTab: [
            { value: 'general', title: 'account.general_information'},
            { value: 'track', title: 'account.order_tracking'},
            { value: 'information', title: 'account.product_information'},
         ],
         activeScroll: { value: "general" },
         order: null,
         showScrollTrackers: false, //debugging
         showNavTrackers: false, //debugging
         // counters
         mainNavCounter: null,
         //visibility percentages
         sectionVisible: {
            general: null,
            track: null,
            information: null
         },
         typeInvoices: null
      }
   },
   computed: {
      ...mapGetters({
         orderStatus: "account/orderStatus",
         branches: "branches/branches",
         user: "auth/userData",
      }),
      filterStateOrderById() {
         return this.orderStatus.filter((state) => state.id === this.order.order_status_i)[0]
      },
      thresholdList(){ //intersection observer utility
         let thresholds = [];
         const numSteps = 20;

         for (let i = 1.0; i <= numSteps; i++) {
            let ratio = i / numSteps;
            thresholds.push(ratio);
         }
         thresholds.push(0);
         return thresholds;
      },
   },
   methods: {
      async getOrder(){        
         const url = this.$route.path;
         const result = url.match(/\/([^/]+)\/([^/]+)\//)[2];
         this.typeInvoices = result
         if(result == 'invoices'){
            let response = await http.get('invoices/'+this.$route.params.id)
            this.order = response.data.data
         } else {
            let response = await http.get('orders/'+this.$route.params.id)
            this.order = response.data.data
         }
      },
      scrollTo({value}){
         value === "track" ? $scrollview.scrollToComponent(value, 150) : $scrollview.scrollToComponent(value, 120) 
      },
   },
   watch: {
      sectionVisible: {
            //on each change this will evaluate every value to change the activeScroll property we could also evaluate the last who achieved the 100% value. fo
            handler: function ({ general, track, information}) {
               clearTimeout(this.mainNavCounter)
               let mainNav = this.$refs.mainNav.$el;
               this.mainNavCounter = setTimeout(() => {
               if (general > track) {
                  if (general > information ) {
                     mainNav.scroll({left: 0, behavior: 'smooth'}); //this scrolls the main-nav horizontally
                     this.activeScroll = {value: 'general'}
                  } else {
                     mainNav.scroll({left: 400, behavior: 'smooth'});
                     this.activeScroll = {value: 'information'}
                  }
               } else {
                  if (track > information ) {
                     if (general < 45) {
                        mainNav.scroll({left: 200, behavior: 'smooth'});
                        this.activeScroll = {value: 'track'}
                     }
                  } else {
                     mainNav.scroll({left: 400, behavior: 'smooth'});
                     //an special case for xxl displays and not many products (2) on this component
                     this.activeScroll = {value: 'information'}
                  }
               }
               }, 50)
            },
            deep: true
         }
   },
   async mounted() {
      try {
         this.$store.commit("common/SET_STATE_LOADING", true)
         await this.$store.dispatch("account/getOrderStatus")
         await this.getOrder();
      } catch (e) {
         console.log(e);
      } finally {
         this.$store.commit("common/SET_STATE_LOADING", false)
      }
   // Intersection Observer (other options brought several issues on mobile versions or smaller page heights).
   // This also could be improved by integrating promises.
      let observableSections = [this.$refs.trackOrder, this.$refs.cardOrder, this.$refs.productInformation]

      observableSections.map( ({$el}) => {
         let observer;
         let options = {
            rootMargin: "0px",
            threshold: this.thresholdList
         };

         let handleIntersect = (entries) => {
            entries.forEach((entry) => {
               this.sectionVisible[$el.id] = Math.floor(entry.intersectionRatio * 100)
            });
         }
         observer = new IntersectionObserver(handleIntersect, options);
         observer.observe($el)
         // observer.forEach(observer.observe($el))
      })
   },
   destroyed(){
      clearTimeout(this.mainNavCounter)
   }
}
</script>

<style scoped>
/* OrderList address */
   .orders-link svg {
      transition: var(--transition-1);
      fill: var(--blue-600) !important;
   }
   .orders-link:hover span {
      color: var(--main-red) !important;
   }
   .orders-link:hover svg {
      fill: var(--main-red) !important;
   }
   .order-details {
      border: none;
   }
   .options-tab {
      position: sticky;
      background: white;
      padding: 1.1rem 2rem 1.9rem .5rem;
      z-index: 2;

      /* giving a position is what really triggers its sticky behaviour */
      top: 64px;
      border-radius: 0px 0px 5px 5px;
   }

   .options-tab > ul::v-deep > li > a {
      margin-right: 3.3rem !important;
   }

   .options-tab > ul {
      height: fit-content;
   }
.card-container {
   padding-left: 0;
   padding-right: 0;

   border-radius: 14px;
   box-shadow: none !important;
}
@media (min-width : 768px) {
   .card-container {
      padding: 1.5rem;
   }
}
@media (min-width : 992px) {
   .card-container {
      box-shadow: var(--card-shadow) !important;
   }
   .right-panel {
      max-width: 390px;
   } 
}
@media (min-width : 1200px) {
   .card-container{
      padding: 2rem;
   }
   .options-tab > ul::v-deep > li > a {
      margin-right: 1rem !important;
   }
}
@media (min-width : 1400px) {
   .options-tab {
      padding-right: 5.5rem;
   }
}

/* deactivating the middle ioption styles */
.removing-tracking-style::v-deep > li:nth-child(2) > a {
   color: var(--dark-gray-1001) !important;
   font-weight: 400 !important;
}

.removing-tracking-style::v-deep > li:nth-child(2) > a:after {
   transform: scaleX(0);
}
.section-percentages{
   bottom: 0px; 
   left: 0; 
   z-index: 10;
   
   box-shadow: 0 0 5px 0 rgb(0 0 0 / 20%);
   border-radius: 5px;
}
</style>


