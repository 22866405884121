<template>
        <div>
            <h3 class="m-0 title-18">{{$t("checkout.pickup_address")}}</h3>
            <div class="d-flex align-items-center mt-3 mb-2">
                <span class="title-16 mb-0 text-limited text-capitalize">{{branch.description | textFormat}}</span>
            </div>
            <div class="d-flex flex-column">
                <div class="d-flex align-items-center">
                    <span class="subtitle-14 weight-300 text-capitalize">{{branch.city_name.toLowerCase()}}, {{branch.address.toLowerCase()}}</span>
                </div>
                <div class="d-flex align-items-center"> 
                    <span class="size-15 weight-400 color-dark-gray-200">Tel:
                        <span class="ms-1 weight-300 color-dark-gray-200" v-if="branch.telephone">{{branch.telephone | phoneFormat}}</span>
                        <span class="ms-1 weight-300 color-dark-gray-200" v-else>(809) 581-7666</span>
                    </span>
                </div>
            </div>
            <div class="d-flex flex-column mt-3">
                <span class="size-15 weight-600">{{$t("checkout.pick_up_incharge")}}:</span>
                <span class="size-14 weight-300 color-dark-gray-200" v-if="partner.entity">({{$t("account.me")}}) <b class="weight-300 ms-1">{{user.entity.first_name}} {{user.entity.last_name}}</b></span>
                <span class="size-14 weight-300 color-dark-gray-200 text-capitalize" v-else>{{partner.full_name.toLowerCase()}}</span>
            </div>
            <hr class="color-gray-1001 mb-4">
            <div class="d-flex align-items-center justify-content-between mb-3 mt-3">
                <h5 class="d-flex align-items-start align-items-xl-center mb-0 weight-400 title-15">
                    <icon-checkout-address :color="'#c40316'" :size="18" class="me-1"/>
                    {{$t("account.branch_location")}}
                </h5>
                <ul class="nav nav-pills flex-lg-column flex-xl-row align-items-lg-end justify-content-start justify-content-lg-end justify-content-xl-start">
                    <li class="nav-item" v-for="(style, index) in mapStyles" :key="index">
                    <div class="d-flex align-items-center">
                        <a @click.prevent="styleSelected = index" :class="{'selected': index === styleSelected  }" href="#" class="size-14 main-link option">
                            {{ $t("cart."+index)}}
                        </a>
                    </div>
                    </li>
                </ul>
            </div>
            
            <MglMap v-if="branch"  style="height: 210px;border-radius:10px; overflow: hidden;" :center="[branch.long, branch.lat]" 
                :accessToken="accessToken" :zoom="14" :dragRotate="false"  @load="onMapLoaded" 
                :mapStyle="mapStyles[styleSelected]">
                <MglMarker  style="cursor: pointer" :coordinates="[branch.long, branch.lat]" :draggable="false"  color="#c40316">
                </MglMarker>
                <MglNavigationControl position="top-right"/>
            </MglMap>
        </div>
</template>

<script>
import { MglMap, MglMarker, MglNavigationControl } from 'v-mapbox'
import { mapGetters } from "vuex"
import Mapbox from "mapbox-gl";
import 'mapbox-gl/dist/mapbox-gl.css'
import IconCheckoutAddress from '../../../checkout/assets/svg/iconCheckoutAddress.vue';

export default {
    name: 'PickUpDetails',
    components: {
        MglMap,
        MglMarker,
        MglNavigationControl,
        IconCheckoutAddress
    },
    props: {
        branch:{
            type: Object
        },
        partner: {
            type: Object,
        }
    },
    computed:{
        ...mapGetters({
            user: "auth/userData", 
        })
    },
    data() {
        return {
            accessToken: process.env.VUE_APP_MAPBOX_KEY,
            mapStyles: {
                Mapa: "mapbox://styles/fbernabe/clasqq2sj002w14qp6xg4dkus",
                Satellite: "mapbox://styles/fbernabe/clbcjvwmq002016nkqy7vxtzt",
            },
            styleSelected: "Mapa",
        }
    },
    methods:{
        onMapLoaded(){
            this.mapbox = Mapbox;
        },
    }
}
</script>
<style scoped>
@media (max-width : 992px) {
    li.nav-item:not(:last-child) div .option{
        border-right: 1.5px solid var(--gray-100);
        padding-right: .5rem;
        margin-right: .5rem;
    }
}
@media (min-width : 992px) {
    h5 { width: 70px; }
}
@media (min-width : 1200px) {
    h5 { width: max-content; }
    li.nav-item:not(:last-child) div .option{
        border-right: 1.5px solid var(--gray-100);
        padding-right: .5rem;
        margin-right: .5rem;
    }
}
.option {
    color: var(--gray-1001) !important;
}
.option:hover {
    color: var(--dark-gray-300) !important;
}
.selected {
    font-weight: 600;
    color: var(--blue-600) !important;
}
.selected:hover {
    color: var(--blue-600) !important;
}
</style>