import { render, staticRenderFns } from "./iconDepositExpress.vue?vue&type=template&id=98cdb2dc&scoped=true"
import script from "./iconDepositExpress.vue?vue&type=script&lang=js"
export * from "./iconDepositExpress.vue?vue&type=script&lang=js"
import style0 from "./iconDepositExpress.vue?vue&type=style&index=0&id=98cdb2dc&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "98cdb2dc",
  null
  
)

export default component.exports