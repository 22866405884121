<template>
    <icon-base :width="size + 'px'" :fill="color">
        <g>
            <g>
                <g>
                    <path class="st0" d="M216.7,100.2c-2.9,3-5.8,5.9-8.7,8.9c-4.2,4.3-8.4,8.7-12.6,13c0.6,0.8,1.2,1.5,1.7,2.4     c4.8,7.7,5.2,17.2,0.9,24.9c-4.7,8.4-13.8,13.3-25.1,13.3h-43.5c-2.9,0-5.6-2.1-6-5c-0.5-3.6,2.3-6.7,5.8-6.7H173     c7,0,12.2-2.6,14.8-7.2c2.2-4,2-9-0.6-13c-1.2-1.9-2.8-3.5-4.7-4.7c-3.7-2.3-8.1-3-12.8-3c-16.6,0.1-33.2,0-49.7,0.1     c-1.4,0-2.7-0.2-4-0.7c-14.7-5.5-29.5-5.5-44.4-0.7c-14.1,4.6-24.9,13.3-32.4,26.1c-0.5,0.8-0.8,1.6-2.1,1.5     c-5.9-0.6-10.4,2.2-14.3,6.8c-5.1,6-10.5,11.7-16.5,16.8c-6.4,5.4-7.4,7.7-3,12.1c18.3,18.4,36.6,36.8,54.9,55.3     c1.3,0.1,2.2,0.1,3.6,0.2c0.9-0.7,1.9-1.3,2.7-2.1c7.5-7.4,15-14.8,22.6-22.2c4-4,5.4-8.7,4.3-14.2c-0.1-0.6-0.5-1.2,0.1-1.8     c4.5-4.5,8.9-8.7,16.1-8.6c20.1,0.3,40.3,0.1,60.4,0.1c2.7,0,5.4,0,8-0.3c10.9-1.3,20.3-5.7,27.4-14.1     c10.8-12.7,21.5-25.5,32-38.3c2-2.4,14.1-17.8,14.1-31.8C249.5,91.8,227.2,89.4,216.7,100.2z M80.2,208.9     c-6.2,6.2-12.5,12.3-18.7,18.5c-0.9,0.9-1.4,0.9-2.3,0c-15.3-15.4-30.5-30.8-45.8-46.2c-0.8-0.8-0.9-1.2,0-2.1     c6.2-6.2,12.4-12.4,18.6-18.6c2.4-2.3,4.9-2.3,7.3,0.1c13.7,13.6,27.4,27.3,41.1,41c1,1,1.6,2.2,1.6,3.7     C81.9,206.7,81.3,207.9,80.2,208.9z M111.1,161.1c-1.9-0.4-3.4-1.9-3.8-3.8c-0.7-3.2,1.9-6,5-5.9c2.7,0.1,4.7,2.2,4.6,4.9     C116.8,159.4,114.2,161.7,111.1,161.1z"/>
                    <g>
                        <g>
                            <g>
                                <g>
                                    <path class="st1" d="M135.6,9.7c17.3,0,34.6,0,52,0c8.1,0,12.7,4.6,12.7,12.7c0,4.3,0,8.6,0,12.9c0,3.4-1.3,4.8-4.7,4.8         c-1.1,0-2.2,0-3.3,0c-4.1,0.2-7.3,3.6-7.2,7.7c0,4,3.1,7.2,7.2,7.5c1.4,0.1,2.9-0.1,4.3,0.1c2.3,0.2,3.7,1.5,3.7,3.7         c0.1,5.5,0.2,11-0.1,16.5c-0.3,5.8-5.4,10.1-11.3,10.2c-6.1,0.1-12.2,0-18.2,0c-29.1,0-58.1,0-87.2,0c-8.5,0-13-4.5-13-12.9         c0-4.2,0-8.4,0-12.7c0-3.4,1.3-4.8,4.7-4.8c1.1,0,2.2,0,3.3,0c4.1-0.3,7.3-3.6,7.2-7.7c0-4-3.2-7.2-7.2-7.5         c-1.3-0.1-2.7,0-4.1,0c-2.5-0.1-3.9-1.5-4-4c-0.1-5.2-0.1-10.5,0-15.7c0.2-6.1,5.3-10.7,11.9-10.7         C100.1,9.7,117.8,9.7,135.6,9.7z M120.1,70.6c1.9-0.1,2.8-1.2,3.8-2.4c9.6-12.1,19.3-24.1,28.9-36.2c1.9-2.4,2-4.7,0.1-6.2         s-4-1-6,1.5c-5.2,6.4-10.3,12.9-15.5,19.3c-4.6,5.8-9.3,11.6-13.9,17.4c-1.1,1.4-1.4,2.9-0.7,4.5         C117.4,69.9,118.7,70.5,120.1,70.6z M120.1,47.7c6.3,0,11.4-5.1,11.4-11.4c0-6.2-5-11.3-11.2-11.4c-6.3-0.1-11.5,5-11.6,11.3         C108.7,42.5,113.8,47.7,120.1,47.7z M150.6,70.6c6.3,0,11.4-5.3,11.3-11.6c-0.1-6.2-5.1-11.2-11.3-11.3         c-6.3,0-11.5,5.1-11.4,11.4C139.2,65.5,144.3,70.6,150.6,70.6z"/>
                                </g>
                            </g>
                            <g>
                                <path class="st1" d="M120,32.5c2-0.1,3.7,1.5,3.8,3.6c0.1,2.1-1.6,4-3.7,4c-2,0-3.7-1.6-3.8-3.6        C116.3,34.4,117.9,32.6,120,32.5z"/>
                            </g>
                            <g>
                                <path class="st2" d="M150.6,55.4c2.1,0,3.8,1.8,3.8,3.9c-0.1,2-1.8,3.7-3.8,3.7c-2.1,0-3.8-1.8-3.8-3.9        C146.9,57,148.6,55.4,150.6,55.4z"/>
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </g>
    </icon-base>
</template>
<script>

export default {
name: 'iconNoteCredit',
components: {
    iconBase: () => import('@/common/components/iconBase.vue')
},
props: {
    size: {
        type: Number,
        default: 20
    },
    color: {
        type: String,
        default: 'var(--main-navy)'
    },
    
}
}
</script>

<style scoped>
    .st0{fill:#234F6D;}
	.st1{fill:#FFB949;}
	.st2{fill:#F7C75C;}
</style>
