<template>
    <div>
        <div class="d-flex flex-column justify-content-between mb-4" style="margin-top: 2.5rem">
            <h3 class="mb-4 title-18 weight-600">{{$t("account.product_information")}}</h3>
            <span class="subtitle-18 color-dark-gray-300 text-capitalize">
                {{branch.description | textFormat}}
                <b class="ms-4 color-main-navy weight-400">({{quantityOfProducts}})</b>
                {{$t("checkout.articles")}}
            </span>
        </div>
        <div class="product-list-container position-relative">
            <div class="product-list overflow-auto" ref="productList" style="max-height: 43rem;">
                <div v-for="({product, amount, quantity}, index) in order.order_details" ref="product" class="row m-0 product-information" :key="product.codarticulo" style="padding-right: 16px;">
                    <hr class="my-4" :style="{marginTop: index === 0 ? '0 !important':'1.5rem !important'}" />
                    <div class="d-flex col-md-12 col-lg-8 align-items-center">
                        <router-link :to="`/products/${product._id}`" class="product-img align-middle text-center cursor-pointer">
                            <image-product :styleObject="{width: '90px', height: '90px','object-fit': 'contain', border: '1px solid var(--gray-50)', borderRadius: '5px'}" :source="getImages(product.imageGallery)" class="me-3 img-product-details"/>
                        </router-link>
                        <div class="d-flex flex-column text-limited w-100">
                            <span class="size-16 weight-600 text-limited product-title" data-toggle="tooltip" data-placement="top" :title="product.description | textFormat">
                                {{product.description | textFormat}}
                            </span>
                            <div class="product-data d-flex size-13 justify-content-between">
                                <span class="weight-600">{{$t('product.brand')}}: <b class="subtitle-13 color-dark-gray-200">{{product.brand | textFormat}}</b></span>
                                <span class="weight-600 mx-lg-3">{{$t('product.quantity')}}: <b class="subtitle-13 color-dark-gray-200">{{quantity}}</b></span>
                                <span class="weight-600 d-none d-xxl-block">{{$t('product.color')}}: <b class="subtitle-13 color-dark-gray-200">{{product.color | textFormat}}</b></span>
                            </div>
                            <span class="weight-600 subtitle-13">{{$t('product.price')}}: <b class="color-dark-gray-200 subtitle-13">{{amount | moneyFormat}}</b> </span>
                        </div>
                    </div>
                    <div class="product-actions col-md-12 col-lg-4 text-end ms-auto ms-xl-0 px-md-0 mt-3 mt-md-1 mt-lg-0">
                        <h4 class="title-16">{{$t("checkout.total")}} {{amount * quantity | moneyFormat}}</h4>
                        <div class="d-flex justify-content-end align-items-end flex-column flex-sm-row flex-lg-column">
                            <div style="height: 32px;" class="mb-2 mb-sm-0 mb-lg-2" data-toggle="tooltip" data-placement="top" :title="disableBtn(product._id) ? $t('account.product_has_review'):''">
                                <main-button :disabled="disableBtn(product._id) || product.type_id == 4" class="mb-2 mb-sm-0 mb-lg-2 me-0 me-sm-3 me-lg-0" title="account.write_review" @click.native="selectedProduct = product; showReview = true;"/>
                            </div>
                            <div class="second-btn-container position-relative d-flex justify-content-end no-wrap px-0" @mouseleave="() => {select = null; showOptions = false;}">
                                <second-button @click.native="() => {select = product; showOptions = !showOptions}" :disabled="product.type_id == 4" ref="moreActions" class="w-100" title="account.more_actions" iconRight icon>
                                    <icon-arrow :size="13" class="ms-2 actions-arrow" :class="{'rotated': show(product) && showOptions}"/>
                                </second-button>
                                <div v-if="show(product)" class="options position-absolute d-flex align-items-start justify-content-center bg-white flex-column m-0 p-2">
                                    <li class="subtitle-14" @click="buyAgain">
                                        {{$t("account.buy_again")}}
                                    </li>
                                    <li class="subtitle-14 mt-2">
                                        {{$t("account.defective_product")}}
                                    </li>
                                </div>
                            </div>
                        </div>
                    </div> 
                    <hr v-if="order.order_details.length === (index + 1)" class="mt-4 mb-0">
                </div>
                <slot />
            </div>

        </div>
        <write-review v-if="showReview" :product="selectedProduct" @closeWriteReview="closeWriteReview" @closeModal="showReview = false; selectedProduct = null;"/>
        <scroll-up-button />
    </div>
</template>

<script>
import MainButton from '../../../../common/components/buttons/MainButton.vue';
import SecondButton from '../../../../common/components/buttons/SecondButton.vue';
import ImageProduct from '../../../products/components/Image.vue';
import IconArrow from '../../../../common/svg/iconArrow.vue';
import WriteReview from './WriteReview.vue';
import ScrollUpButton from '../../../../common/components/buttons/ScrollUpButton.vue';
import { productMixin } from "../../../products/helpers/mixin/product-mixin";
import { mapGetters } from "vuex"

export default {
    components: { 
        ImageProduct, 
        MainButton, 
        SecondButton, 
        IconArrow, 
        WriteReview,
        ScrollUpButton 
    },
    name: "ProductInformation",
    props: {
        order: {
            type: Object
        },
    },
    data() {
        return {
            showOptions: false,
            lastSelect: null,
            select: null,
            selectedProduct: null,
            showReview: false,
            productsWithReviews: [],
        }
    },
    watch: {
        select(val){
            if (val !== null) 
            this.lastSelect = val
        }
    },
    computed: {
        ...mapGetters({
            branches: "branches/branches",
        }),
        quantityOfProducts(){
            let sum = 0;
            for (let i = 0; i < this.order.order_details.length; i++) {
                const element = this.order.order_details[i];
                sum += element.quantity
            }
            return sum;
        },
        branch(){
            let branch = this.branches.filter((branch) => branch.codplace === this.order.branch_code)
            return branch[0];
        },
    },
    mixins: [productMixin],
    methods: {
        disableBtn({userHasReview, codarticulo}){
            let reviewMadeRecently;
            for(const product of this.productsWithReviews) {
                if (product === codarticulo) {
                    reviewMadeRecently = true;
                    break;
                }
            }
            return userHasReview || reviewMadeRecently;
        },
        closeWriteReview(){
            this.showReview = false;
            this.productsWithReviews.push(this.selectedProduct.codarticulo)
        },
        show(product){
            return product.codarticulo === this.select?.codarticulo && this.showOptions;
        },
        async buyAgain(){
            await this.$store.dispatch("cart/updateQuantity", { product: {
                product: this.select.product,
                quantity: this.select.quantity,
                total_amount: 0,
                cod_articulo: this.select.product_code,
            }});
            let data = { type: 'addProduct', title: 'product.product_added', show: true, quantity: 1, img: this.getImages(this.lastSelect.product.imageGallery), product: this.lastSelect.product}
            this.$store.commit("common/SET_NOTIFY", data)  
        },
    },
}
</script>

<style scoped>
    .attached-div {
        width: 160px;
        right: 37px;

        border-bottom-right-radius: 8px;
        border-bottom-left-radius: 8px;
    }
    .product-information:last-child {
        padding-bottom: 3.5rem;
    }
    .second-btn-container, .main-button, .options {
        width: 160px;
    }
    .options {
        top: 32px;
        z-index: 1;

        margin-top: 2px;
        list-style: none;

        border-radius: 0px 0px 10px 10px;
        box-shadow: 0px 0px 10px 0.5px #909da41c;
        animation: options-animation .3s;
    }

    li:hover {
        color: #6a9ce5 !important;
        cursor: pointer;
    }
    @keyframes options-animation {
        from {
            opacity: 0;
            transform: translateY(-10%);
        }
        to {
            opacity: 1;
            transform: translateY(0);
        }
    }
    .rotated {
        transform: rotate(-90deg) !important;
    }
    .actions-arrow {
        transform: rotate(90deg);
        transition: var(--transition-1)
    }
    .product-data {
        max-width: 180px;
        flex-direction: column;
    }
    .product-img {
        margin-right: 1rem;
    }
@media (min-width : 768px) {
    .product-img {
        margin-left: 1.3rem; 
        margin-right: 1.5rem;
    }
    .product-data {
        flex-direction: row;
    }
}
@media (min-width : 992px) {
    .product-actions {
        padding-right: 2.5rem;
    }
    .product-title {
        max-width: 215px;
    }
}
@media (min-width : 1200px) {
    .product-title {
        max-width: 280px;
    }
}

</style>
