<template>
    <div>
        <h3 class="m-0 title-18">{{$t("checkout.delivery_address")}}</h3>
            <div class="d-flex align-items-center mt-3 mb-2">
                <span class="title-16 mb-0 text-limited">{{address.name | textFormat}}</span>
                <span v-if="address.default" class="color-dark-gray-200 ms-2">|</span>
                <div class="d-flex align-items-center ms-2" v-if="address.default">
                    <icon-star :size="15" class="me-2"/>
                    <span class="title-15 mb-0">{{$t("checkout.default")}}</span>
                </div>
            </div>
            <div class="d-flex flex-column">
                <div class="d-flex align-items-center">
                    <span class="subtitle-14 text-limited weight-300">{{address.address_line2 || address.address_line}}, {{address.city_name}}</span>
                </div>
                <div class="d-flex align-items-center">
                    <span class="subtitle-14 text-limited title-15 mb-0 color-dark-gray-200">Ref: <span class="weight-100 color-dark-gray-200">{{address.reference}}</span></span>
                </div>
                <div class="d-flex align-items-center">
                    <span class="subtitle-14 title-15 mb-0">Tel:<span class="ms-2 weight-100">{{address.phone | phoneFormat}}</span></span>
                </div>
            </div>
            
            <h6 v-if="order.order_primary.option_name == 'nacional_delivery'" class="subtitle-14 mb-0 d-flex align-items-center" style="position: relative; top: 10px;">
                <template>
                    <img :src="order.order_primary.shipment.provider_image_75" :size="20" class="me-2 d-none d-md-block">
                    <span class="color-dark-gray-300">{{$t(order.order_primary.shipment.provider)}}</span>
                </template>
            </h6>
            <div class="mt-4 d-flex flex-wrap">
                <!-- the ternary here is for testing  convenience need to remove later -->
                <span class="size-14 titleSpan">{{$t("checkout.delivery_price")}}: 
                    <strong class="titleSpan"> {{delivery | moneyFormat}}</strong>
                </span>
                <span v-if="order.order_primary.option_name != 'nacional_delivery'" class="main-link ms-auto me-3" style="font-size: 13px;" @click.prevent="showDeliveryDetail = true">{{$t("checkout.see_details")}}</span>
                
                <div class="d-flex flex-column align-items-start">
                    <span v-if="order.order_primary.option_name != 'nacional_delivery'" class="title-14 color-inactive-main-navy weight-300">
                        {{$t("checkout.delivery_from_4_to_5")}}
                    </span>
                    <span v-else class="title-14 color-inactive-main-navy weight-300">
                        {{$t(order.order_primary.shipment.duration_terms)}}
                    </span>
                </div>
            </div>
            <hr class="color-gray-1001 mb-4">
        <div>
            <div class="d-flex align-items-center justify-content-between mb-3 mt-3">
                <div class="d-flex">
                    <icon-checkout-address :color="'#c40316'" :size="18" class="me-1"/>
                    <h5 class="mb-0 weight-400 title-16">{{$t("checkout.my_location")}}</h5>
                </div>
                <ul class="nav nav-pills">
                    <li class="nav-item" v-for="(style, index) in mapStyles" :key="index">
                    <div class="d-flex align-content-center">
                        <a @click.prevent="styleSelected = index" :class="{'selected': index === styleSelected  }" href="#" class="size-14 main-link option weight-400">
                        {{ $t("cart."+index)}}
                        </a>
                    </div>
                    </li>
                </ul>
            </div>
            <MglMap v-if="address"  style="height: 210px;baddress-radius:10px; overflow: hidden;" :center="[address.longitud, address.latitud]" 
                :accessToken="accessToken" :zoom="14" :dragRotate="false"  @load="onMapLoaded" 
                :mapStyle="mapStyles[styleSelected]" >
                <MglMarker  style="cursor: pointer" :coordinates="[address.longitud, address.latitud]" :draggable="false"  color="#c40316">
                </MglMarker>
                <MglNavigationControl position="top-right"/>
            </MglMap>
            <delivery-detail v-if="showDeliveryDetail" @closeModal="showDeliveryDetail = false" :branch="branchSelected" :delivery="getDeliveryDetails" :lat="order.delivery_detail.customer_latitud" :long="order.delivery_detail.customer_longitud" :quantity="order.number_of_items"/>
        </div>
    </div>
</template>

<script>
import { MglMap, MglMarker, MglNavigationControl } from 'v-mapbox'
import Mapbox from "mapbox-gl";
import 'mapbox-gl/dist/mapbox-gl.css'
import IconCheckoutAddress from '../../../checkout/assets/svg/iconCheckoutAddress.vue';
import IconStar from '../../../../common/svg/iconStar.vue';
import DeliveryDetail from '../../../checkout/components/modals/DeliveryDetail.vue';
import { mapGetters } from 'vuex';
export default {
    name: "AddressDetails",
    components: {
        MglMap,
        MglMarker,
        MglNavigationControl,
        IconCheckoutAddress,
        IconStar,
        DeliveryDetail
    },
    props:{
        address:{
            type: Object
        },
        delivery:{
            type: [Number, String]
        },
        order:{
            type: Object
        }
    },
    data() {
        return {
            accessToken: process.env.VUE_APP_MAPBOX_KEY,
            mapStyles: {
                Mapa: "mapbox://styles/fbernabe/clasqq2sj002w14qp6xg4dkus",
                Satellite: "mapbox://styles/fbernabe/clbcjvwmq002016nkqy7vxtzt",
            },
            styleSelected: "Mapa",
            showDeliveryDetail: false,
        }
    },
    methods:{
        onMapLoaded(){
            this.mapbox = Mapbox;
        },
    },
    computed:{
        ...mapGetters({
            addressSelectedAvailable: "account/addressSelectedAvailable",
            branchSelected: "branches/branchSelected",
            addressSelected: "account/addressSelected",
            quantity: 'cart/quantityProducts',
            branches: "branches/branches"
        }),
        getDeliveryDetails(){
            let obj = {
                ...this.order.delivery_detail,
                min: this.order.delivery_detail.minutes,
                km: this.order.delivery_detail.kilometers,
                price: this.order.delivery_detail.amount
            }
            obj.coords = JSON.parse(obj.coords)
            return obj
        }
    }
}
</script>
<style scoped>
.option {
    color: var(--gray-1001) !important;
}
.option:hover {
    color: var(--dark-gray-300) !important;
}
.selected {
    font-weight: 600 !important;
    color: var(--blue-600) !important;
}
.selected:hover {
    color: var(--blue-600) !important;
}
li.nav-item:not(:last-child) div .option {
    border-right: 1.5px solid var(--gray-100);
    padding-right: .5rem;
    margin-right: .5rem;
}

</style>
