<template>
    <icon-base :width="size + 'px'" :fill="color">
        <g>
            <g>
                <g>
                    <path d="M234.3,49.7H15.7c-8.3,0-15.2,6.7-15.2,15.2v120.1c0,8.3,6.7,15.2,15.2,15.2h218.6c8.3,0,15.2-6.7,15.2-15.2     V64.9C249.3,56.7,242.6,49.7,234.3,49.7z M234.3,58.2c3.8,0,6.7,3.1,6.7,6.7v17.2c-11.8-2-25.9-11.8-25.9-23.9H234.3z M196.8,125     c0,5.6-4.7,10.3-10.3,10.3c-5.6,0-10.3-4.7-10.3-10.3c0-5.6,4.7-10.3,10.3-10.3C192.1,114.7,196.8,119.4,196.8,125z M166.2,125     c0,22.8-18.5,41.3-41.3,41.3S83.6,147.8,83.6,125s18.5-41.3,41.3-41.3C147.7,83.9,166.2,102.2,166.2,125z M63.3,114.7     c5.8,0,10.3,4.7,10.3,10.3c0,5.6-4.7,10.3-10.3,10.3S53,130.6,53,125C53,119.4,57.7,114.7,63.3,114.7z M8.5,64.9     c0.2-3.8,3.3-6.7,7.1-6.7h18.8c0,11.8-14.1,21.9-25.9,23.9V64.9z M15.7,192c-3.8,0-6.7-3.1-6.7-6.7v-17.2     c11.8,2,25.9,11.8,25.9,23.9H15.7z M241.5,185.3c-0.4,3.6-3.6,6.7-7.1,6.7h-18.8c0-11.8,14.1-21.9,25.9-23.9V185.3z"/>
                    <path d="M122,104.7v2.2c-2.9,0.7-5.4,1.8-7.1,3.6c-2,2-3.1,4.5-3.1,7.4c0,2.5,0.7,4.5,1.8,5.8     c1.1,1.3,2.7,2.5,4.2,3.1s3.6,1.3,6,2c1.8,0.4,3.1,0.9,4,1.1c0.9,0.2,1.6,0.7,2,1.1c0.4,0.4,0.7,0.9,0.7,1.6     c0,1.3-0.4,2.2-1.3,2.9c-1.1,0.7-2.5,1.1-4.5,1.1c-1.6,0-2.9-0.2-4.2-0.7c-1.3-0.4-2.7-1.1-4.2-2c-0.4-0.2-0.9-0.4-1.1-0.7     c-0.4-0.2-0.9-0.2-1.3-0.2c-0.7,0-1.3,0.2-2.2,1.1c-0.4,0.7-0.9,1.6-0.9,2.5c0,0.7,0,1.1,0.2,1.8c0.2,0.4,0.7,0.9,1.1,1.3     c1.3,1.1,2.9,2,4.7,2.7c1.6,0.4,3.1,0.9,4.7,1.1v2c0,0.9,0.2,1.8,0.9,2.5c0.7,0.7,1.6,0.9,2.5,0.9c0.9,0,1.8-0.2,2.5-0.9     c0.7-0.7,0.9-1.6,0.9-2.5v-2.2c2.9-0.7,5.1-1.8,6.9-3.6c2-2,2.9-4.5,2.9-7.1c0-2.5-0.7-4.2-1.8-5.6s-2.5-2.5-4.2-3.1     c-1.6-0.7-3.6-1.3-5.8-1.8c-1.8-0.4-3.1-0.9-4.2-1.1c-0.9-0.2-1.6-0.7-2.2-1.3c-0.4-0.4-0.7-1.1-0.7-2c0-1.3,0.4-2.2,1.3-2.9     c1.1-0.7,2.5-1.1,4.2-1.1c1.6,0,2.9,0.2,3.8,0.7c1.1,0.4,2.2,1.1,3.6,2c0.7,0.4,0.9,0.7,1.3,0.7c0.4,0.2,0.7,0.2,1.1,0.2     c0.7,0,1.3-0.2,2.2-1.1c0.4-0.7,0.9-1.6,0.9-2.5c0-1.3-0.4-2.2-1.6-3.1c-2.2-1.8-4.9-3.1-8-3.6v-2.2c0-0.9-0.2-1.8-0.9-2.5     c0,0,0,0,0,0c-1.8-1.7-4.8-0.2-4.9,2.2C122,104.5,122,104.6,122,104.7z"/>
                </g>
            </g>
        </g>
    </icon-base>
</template>
<script>

export default {
name: 'iconConfirmationCash',
components: {
    iconBase: () => import('@/common/components/iconBase.vue')
},
props: {
    size: {
        type: Number,
        default: 20
    },
    color: {
        type: String,
        default: 'var(--main-navy)'
    },
    
}
}
</script>

<style scoped>
    .st0{fill:#DCE4EA;}
    .st1{fill:#111820;}
</style>
